<template>
  <v-app>
    <v-main>
      <v-container>
        <v-btn color="primary" @click="createNewWorkout">Create a New Workout</v-btn>
        <v-row v-if="workouts.length === 0" class="ma-4">
          <v-col>
            <v-alert type="info">No workouts available. Please create a new workout.</v-alert>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col v-for="(workout, index) in workouts" :key="index" cols="12" md="6" lg="4">
            <v-card>
              <v-card-title>{{ workout.routineDescription }}</v-card-title>
              <v-card-subtitle>Day of the Week: {{ workout.dayOfWeek }}</v-card-subtitle>
              <v-card-text>
                <div>
                  Starred: <v-icon v-if="workout.isFavorite">mdi-star</v-icon><v-icon v-else>mdi-star-outline</v-icon>
                </div>
                <div>Date Added: {{ new Date(workout.createdDate).toLocaleString() }}</div>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="copyWorkout(index)">Copy</v-btn>
                <v-btn text @click="editWorkout(index)">Edit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      workouts: JSON.parse(localStorage.getItem('workoutsList')) || []
    };
  },
  methods: {
    
    copyWorkout(index) {
      const workout = this.workouts[index];
      const newWorkout = {
        ...workout,
        key: this.generateGUID(),
        createdDate: new Date().toISOString()
      };
      newWorkout.routineDescription = newWorkout.routineDescription + ' [COPY]';

      this.workouts.push(newWorkout);
      localStorage.setItem('workoutsList', JSON.stringify(this.workouts));
      this.refreshWorkouts();
    },
    refreshWorkouts() {
      this.workouts = JSON.parse(localStorage.getItem('workoutsList')) || [];
    },
    createNewWorkout() {
      localStorage.removeItem('exercises');
      localStorage.removeItem('routineDescription');
      localStorage.removeItem('dayOfWeek');
      localStorage.removeItem('isFavorite');
      localStorage.removeItem('createdDate');
      localStorage.setItem('workoutKey', this.generateGUID());

      this.$router.push({ path: '/WorkoutTracker', name: 'WorkoutTracker' });
    },
    editWorkout(index) {
      const workout = this.workouts[index];
      localStorage.setItem('exercises', JSON.stringify(workout.exercises));
      localStorage.setItem('routineDescription', workout.routineDescription);
      localStorage.setItem('dayOfWeek', workout.dayOfWeek);
      localStorage.setItem('isFavorite', JSON.stringify(workout.isFavorite));
      localStorage.setItem('createdDate', workout.createdDate);
      localStorage.setItem('workoutKey', workout.key);
      this.$router.push({ path: '/WorkoutTracker', name: 'WorkoutTracker' });
    },
    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
  }


};
</script>

<style lang="scss" scoped>
.v-container {
  padding: 20px;
  background-color: #f2f2f2;
}
.v-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.v-btn {
  margin-top: 10px;
}
</style>
