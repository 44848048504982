<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col class="text-left no-bottom-padding no-top-padding">
                <v-btn color="secondary" @click="goBackToWorkoutList">Back to Workout List</v-btn>
            </v-col>
            <v-col class="text-right no-bottom-padding no-top-padding">
              <v-btn color="primary" @click="saveRoutine">Save Workout</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right no-bottom-padding no-top-padding">
              <v-btn color="info" @click="copyWorkout">Copy Workout</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right no-bottom-padding no-top-padding">
              <v-btn color="error" @click="deleteWorkout">Delete Workout</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn outlined color="primary" @click="exportRoutine">Export Routine as JSON</v-btn>
              <v-file-input label="Import Routine" @change="importRoutine" prepend-icon="mdi-file-import"></v-file-input>
            </v-col>
          </v-row>

          <v-card class="pa-4 mb-4">
            <v-text-field label="Description" v-model="routineDescription" outlined></v-text-field>
            <v-select :items="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']" label="Day of the Week" v-model="dayOfWeek" outlined></v-select>
            <v-checkbox v-model="isFavorite" label="Star this Routine" prepend-icon="mdi-star"></v-checkbox>
          </v-card>

          <v-btn color="primary" @click="addNewExercise('Primary')"><v-icon >mdi-plus</v-icon> &nbsp; Primary</v-btn> &nbsp;
          <v-btn color="secondary" @click="addNewExercise('Secondary')"><v-icon >mdi-plus</v-icon> &nbsp; Secondary</v-btn>  &nbsp;
          <v-btn style="background-color: rgb(66 66 66); color: white;"  @click="addNewExercise('Support')"><v-icon >mdi-plus</v-icon> &nbsp; Support</v-btn>  &nbsp;

          <v-divider style="border-width: medium;"> </v-divider>
          <br/>
          <v-row v-for="(exercise, index) in groupedExercises" :key="index" class="mb-4">
            <v-col :style="{ backgroundColor: getBackgroundColor(exercise.type) }">
              <v-card >

                <v-card-title>
                    <v-row>
                        <v-col class="text-left  " >
                            <v-chip
                                class="ma-2 no-top-padding no-bottom-padding"
                                label
                                :color="getChipColor(exercise.type)"
                                variant="flat"
                                size="x-large"
                                >
                                    {{ exercise.type.toUpperCase() }}
                            </v-chip>
                            <span style="font-size: x-large; vertical-align: middle;" ><b>{{ exercise.name }}</b>
                            
                                <span v-if="(exercise.warmupSets.length > 0 && exercise.warmupSets.length === exercise.warmupSets.filter(set => set.complete).length && 
                                            exercise.workSets.length > 0 && exercise.workSets.length === exercise.workSets.filter(set => set.complete).length) ||
                                            (exercise.warmupSets.length === 0 && exercise.workSets.length > 0 && exercise.workSets.length === exercise.workSets.filter(set => set.complete).length) ||
                                            (exercise.workSets.length === 0 && exercise.warmupSets.length > 0 && exercise.warmupSets.length === exercise.warmupSets.filter(set => set.complete).length)"
                                            style="font-size: x-large; font-weight: bolder;">
                                    <v-icon  color="success" style="padding-left: 10px;">
                                        mdi-check
                                    </v-icon>
                                    
                                </span>
                            
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-left  no-top-padding " style="margin-top: -8px;">
                            <v-btn icon @click="moveExercise(exercise, 'up')" :disabled="!canMove(exercise, 'up')">
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn icon @click="moveExercise(exercise, 'down')" :disabled="!canMove(exercise, 'down')">
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn> 
                        </v-col>
                        <v-col class="text-right  no-top-padding " style="margin-top: -8px;">
                            <v-btn icon @click="removeExercise(exercise)" style="margin-top: 5px; background-color: rgb(255 224 224) !important">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                 
                </v-card-title>
               

                <v-card-text>
                  <v-text-field label="Exercise Name" v-model="exercise.name" outlined style="padding-bottom: 5px;"></v-text-field>
            
                  <v-text-field
                        label="Weight (Kg)"
                        v-model.number="exercise.weight"
                        type="number"
                        outlined
                        class="mx-1"
                        style="font-size: large; min-width: 50px !important"
                    >
                
                        <template v-slot:append> 
                            <v-btn icon @click="updateExerciseWeight(exercise, 1)" style="margin-left: -5px; background-color: indianred;" >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:prepend> 
                            <v-btn icon @click="updateExerciseWeight(exercise, -1)"  style="margin-right: -5px; background-color: yellowgreen;">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>

                    </v-text-field>
                    <v-text-field
                        label="Warmup Reps"
                        v-model.number="exercise.warmupReps"
                        type="number"
                        outlined
                        class="mx-1"
                        style="font-size: large; min-width: 50px !important"
                    >
                
                        <template v-slot:append> 
                            <v-btn icon @click="updateExerciseWarmupReps(exercise, 1)" style="margin-left: -5px; background-color: indianred;">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:prepend> 
                            <v-btn icon @click="updateExerciseWarmupReps(exercise, -1)"  style="margin-right: -5px; background-color: yellowgreen;">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>

                    </v-text-field>
                    <v-text-field
                        label="Working Reps"
                        v-model.number="exercise.workingReps"
                        type="number"
                        outlined
                        class="mx-1"
                        style="font-size: large; min-width: 50px !important"
                    >
                
                        <template v-slot:append> 
                            <v-btn icon @click="updateExerciseWorkingReps(exercise, 1)" style="margin-left: -5px; background-color: indianred;">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:prepend> 
                            <v-btn icon @click="updateExerciseWorkingReps(exercise, -1)"  style="margin-right: -5px; background-color: yellowgreen;">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>

                    </v-text-field>
                </v-card-text>

                <br/>

                <v-divider></v-divider>

                <v-row class="mb-2 v-row-margin-fix" >
                    <v-col class="text-left no-bottom-padding">
                        <span class="v-card-title no-bottom-padding" >
                            <span style="font-size: x-large; font-weight: bold; text-decoration-line: underline;">
                                Warmup Sets
                            </span>
                            <span v-if="exercise.warmupSets.length > 0 && exercise.warmupSets.filter(set => set.complete).length < 1"  style="font-size: x-large;">
                               &nbsp; ({{ exercise.warmupSets.length }} / {{ exercise.warmupSets.filter(set => set.complete).length }})  
                            </span>
                            <span v-if="exercise.warmupSets.length > 0 && exercise.warmupSets.length !== exercise.warmupSets.filter(set => set.complete).length && exercise.warmupSets.filter(set => set.complete).length > 0"  style="font-size: x-large;">
                               &nbsp; ({{ exercise.warmupSets.length }} / <span style="color: rgb(76 175 80) ;"> {{ exercise.warmupSets.filter(set => set.complete).length }} </span>)  
                            </span>
                            <span v-if="exercise.warmupSets.length > 0 && exercise.warmupSets.length === exercise.warmupSets.filter(set => set.complete).length"  style="font-size: x-large;">
                               &nbsp; ( 
                                <v-icon  color="success">
                                    mdi-check
                                </v-icon>
                                )
                            </span>
                        </span>
                    </v-col>
                    <v-col class="text-right no-bottom-padding ">
                        <v-btn color="primary" @click="addWarmupSet(exercise)">
                                <v-icon >mdi-plus</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>


               <v-row v-for="(set, setIndex) in exercise.warmupSets" :key="'warmup-' + setIndex">
                    <v-col>
                        <v-row class="v-row-margin-fix">
                            <v-col class="d-flex align-center no-bottom-padding no-top-padding no-right-padding">
                                <v-btn icon @click="removeSet(exercise, setIndex, 'warmup')" :disabled="set.complete"  style=" " class="v-btn--size-default-smaller" >
                                    <v-icon color="black">mdi-delete</v-icon>
                                </v-btn>

                                <v-text-field
                                    label="Reps"
                                    v-model.number="set.reps"
                                    type="number"
                                    :readonly="set.complete"
                                    outlined
                                    class="mx-1"
                                    style="font-size: large;"
                                    :disabled="set.complete"

                                    @touchstart.stop.prevent="setSlider(index,set,'warmup', false)"
                                    @focus="setSlider(index,set, 'warmup', false)"

                                >
                                </v-text-field>
                   
                            </v-col>
                            <v-col class="d-flex align-center no-bottom-padding no-top-padding no-left-padding">
                   
                                <v-text-field
                                    label="Weight (Kg)"
                                    v-model.number="set.weight"
                                    type="number"
                                    :readonly="set.complete"
                                    outlined
                                    class="mx-1"
                                    style="font-size: large; min-width: 50px !important"
                                    :disabled="set.complete"
                                    
                                    @touchstart.stop.prevent="setSlider(index,set,'warmup', true)"
                                    @focus="setSlider(index,set, 'warmup', true)"
                                >
                                </v-text-field>

                                <v-checkbox v-model="set.complete" label="" :color="set.complete ? 'success' : ''" style="margin-left: 5px; font-size: 20px;" @click="clearSliderIndex('warmup')"></v-checkbox>
                            </v-col>
                        </v-row>
                      
                    </v-col>
                </v-row>
                <v-row v-if="sliderWarmupSet !== null && sliderWarmupSet && sliderActiveExercise === index && sliderWarmupSetIsWeights === true">
                    <v-col class="d-flex align-center no-bottom-padding no-top-padding " style="padding-top: 35px;" >
                        <v-slider
                            v-model="sliderWarmupSet.weight"
                            :step="1"
                            max="300"
                            min="1"
                            track-color="grey"
                            show-ticks="always"
                            tick-size="1"
                            thumb-label="always"
                            thumb-size="35"
                            thumb-color="red"
                            color="red"
                        >
                        <template v-slot:prepend>
                            <v-btn icon @click="updateSetWeight(sliderWarmupSet, -1)" style="background-color: indianred;" >
                                <v-icon >mdi-minus</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:append>
                            <v-btn icon @click="updateSetWeight(sliderWarmupSet, 1)" style="background-color: yellowgreen;"  >
                                <v-icon >mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        </v-slider>
                    </v-col>
                   
                </v-row>
                <v-row v-if="sliderWarmupSet !== null && sliderWarmupSet && sliderActiveExercise === index && sliderWarmupSetIsWeights === true" class="no-bottom-padding no-top-padding ">
                    <v-col class="d-flex justify-center align-center no-bottom-padding no-top-padding " >
                        <v-btn icon @click="clearSliderIndex('warmup')" style="background-color: black;" class="v-btn-close-small" >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="sliderWarmupSet !== null && sliderWarmupSet && sliderActiveExercise === index && sliderWarmupSetIsWeights === false">
                    <v-col class="d-flex align-center"  style="padding-top: 35px;" >
                        <v-slider
                            v-model="sliderWarmupSet.reps"
                            :step="1"
                            max="30"
                            min="1"
                            track-color="grey"
                            show-ticks="always"
                            tick-size="4"
                            thumb-label="always"
                            thumb-size="35"
                            thumb-color="red"
                            color="red"
                        >
                        <template v-slot:prepend>
                            <v-btn icon @click="updateSetReps(sliderWarmupSet, -1)" style="background-color: indianred;">
                                <v-icon >mdi-minus</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:append>
                            <v-btn icon @click="updateSetReps(sliderWarmupSet, 1)" style="background-color: yellowgreen;" >
                                <v-icon >mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        </v-slider>
                    </v-col>
                </v-row>
                <v-row v-if="sliderWarmupSet !== null && sliderWarmupSet && sliderActiveExercise === index && sliderWarmupSetIsWeights === false" class="no-bottom-padding no-top-padding ">
                    <v-col class="d-flex justify-center align-center no-bottom-padding no-top-padding " >
                        <v-btn icon @click="clearSliderIndex('warmup')" style="background-color: black;" class="v-btn-close-small" >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>


                <br/>
                <br/>


                
                
                <v-divider></v-divider>

                <v-row class="mb-2 v-row-margin-fix" >
                    <v-col class="text-left no-bottom-padding">
                        <span class="v-card-title no-bottom-padding" >
                            <span style="font-size: x-large; font-weight: bold; text-decoration-line: underline;">
                                Work Sets
                            </span>
                            <span v-if="exercise.workSets.length > 0 && exercise.workSets.filter(set => set.complete).length < 1"  style="font-size: x-large;">
                               &nbsp; ({{ exercise.workSets.length }} / {{ exercise.workSets.filter(set => set.complete).length }})  
                            </span>
                            <span v-if="exercise.workSets.length > 0 && exercise.workSets.length !== exercise.workSets.filter(set => set.complete).length && exercise.workSets.filter(set => set.complete).length > 0"  style="font-size: x-large;">
                               &nbsp; ({{ exercise.workSets.length }} / <span style="color: rgb(76 175 80) ;"> {{ exercise.workSets.filter(set => set.complete).length }} </span>)  
                            </span>
                            <span v-if="exercise.workSets.length > 0 && exercise.workSets.length === exercise.workSets.filter(set => set.complete).length"  style="font-size: x-large;">
                               &nbsp; ( 
                                <v-icon  color="success">
                                    mdi-check
                                </v-icon>
                                )
                            </span>
                        </span>
                    </v-col>
                    <v-col class="text-right no-bottom-padding ">
                        <v-btn color="primary" @click="addWorkSet(exercise)">
                                <v-icon >mdi-plus</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
       

                <v-row v-for="(set, setIndex) in exercise.workSets" :key="'work-' + setIndex">
                    <v-col>
                        <v-row class="v-row-margin-fix">
                            <v-col class="d-flex align-center no-bottom-padding no-top-padding no-right-padding">
                                <v-btn icon @click="removeSet(exercise, setIndex, 'work')" :disabled="set.complete" style="" class="v-btn--size-default-smaller" >
                                    <v-icon color="black">mdi-delete</v-icon>
                                </v-btn>
                                
                                <v-text-field
                                    label="Reps"
                                    v-model.number="set.reps"
                                    type="number"
                                    :readonly="set.complete"
                                    outlined
                                    class="mx-1"
                                    style="font-size: large;"
                                    :disabled="set.complete"
                                    
                                    @touchstart.stop.prevent="setSlider(index,set,'work', false)"
                                    @focus="setSlider(index,set, 'work', false)"

                                >
                                </v-text-field>
                                
                            </v-col>
                            <v-col class="d-flex align-center no-bottom-padding no-top-padding no-left-padding">
                              
                                <v-text-field
                                    label="Weight (Kg)"
                                    v-model.number="set.weight"
                                    type="number"
                                    :readonly="set.complete"
                                    outlined
                                    class="mx-1"
                                    style="font-size: large; min-width: 50px !important"
                                    :disabled="set.complete"
                                    
                                    
                                    @touchstart.stop.prevent="setSlider(index,set, 'work', true)"
                                    @focus="setSlider(index,set, 'work', true)"
                                >
                                </v-text-field>
                              
                                <v-checkbox v-model="set.complete" label="" :color="set.complete ? 'success' : ''" style="margin-left: 5px; font-size: 20px;" @click="clearSliderIndex('work')"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Single slider section -->
                <v-row v-if="sliderWorkSet !== null && sliderWorkSet && sliderActiveExercise === index && sliderWorkSetIsWeights === true ">
                    <v-col class="d-flex align-center"  style="padding-top: 35px;" >
                        <v-slider
                            v-model="sliderWorkSet.weight"
                            :step="1"
                            max="300"
                            min="1"
                            track-color="grey"
                            show-ticks="always"
                            tick-size="1"
                            thumb-label="always"
                            thumb-size="35"
                            thumb-color="red"
                            color="red"
                        >
                        <template v-slot:prepend>
                            <v-btn icon @click="updateSetWeight(sliderWorkSet, -1)" style="background-color: indianred;">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:append>
                            <v-btn icon @click="updateSetWeight(sliderWorkSet, 1)"  style="background-color: yellowgreen;" >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        </v-slider>
                    </v-col>
                </v-row>
                <v-row v-if="sliderWorkSet !== null && sliderWorkSet && sliderActiveExercise === index && sliderWorkSetIsWeights === true " class="no-bottom-padding no-top-padding ">
                    <v-col class="d-flex justify-center align-center no-bottom-padding no-top-padding " >
                        <v-btn icon @click="clearSliderIndex('work')" style="background-color: black;" class="v-btn-close-small" >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="sliderWorkSet !== null && sliderWorkSet && sliderActiveExercise === index && sliderWorkSetIsWeights === false">
                    <v-col class="d-flex align-center"  style="padding-top: 35px;" >
                        <v-slider
                            v-model="sliderWorkSet.reps"
                            :step="1"
                            max="30"
                            min="1"
                            track-color="grey"
                            show-ticks="always"
                            tick-size="1"
                            thumb-label="always"
                            thumb-size="35"
                            thumb-color="red"
                            color="red"
                        >
                        <template v-slot:prepend>
                            <v-btn icon @click="updateSetReps(sliderWorkSet, -1)" style="background-color: indianred;">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:append>
                            <v-btn icon @click="updateSetReps(sliderWorkSet, 1)"  style="background-color: yellowgreen;" >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        </v-slider>
                    </v-col>
                </v-row>
                <v-row v-if="sliderWorkSet !== null && sliderWorkSet && sliderActiveExercise === index && sliderWorkSetIsWeights === false" class="no-bottom-padding no-top-padding ">
                    <v-col class="d-flex justify-center align-center no-bottom-padding no-top-padding " >
                        <v-btn icon @click="clearSliderIndex('work')" style="background-color: black;" class="v-btn-close-small" >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>

                <br/>
                <br/>



              </v-card>
            </v-col>
          </v-row>
          <br/>
          
          
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>

export default {
    data() {
        return {
        workoutKey: localStorage.getItem('workoutKey') || this.generateGUID(),
        exercises: [],
        routineDescription: '',
        dayOfWeek: '',
        isFavorite: false,
        createdDate: '',


        sliderActiveExercise: null,

        sliderWarmupSet: null,
        sliderWarmupSetDataType: null,

        sliderWorkSet: null,
        sliderWorkSetDataType: null,

        sliderWarmupSetIsWeights: null,
        
        sliderWorkSetIsWeights: null,


        };
    },
    created() {
        this.loadWorkout();
    },

    computed: {
        groupedExercises() {
        return this.exercises.slice().sort((a, b) => {
            const typeOrder = { 'Primary': 1, 'Secondary': 2, 'Support': 3 };
            return typeOrder[a.type] - typeOrder[b.type];
        });
        }
    },

    
    methods: {


        getBackgroundColor(type) {
            switch (type) {
                case 'Primary':
                return '#1867c0a8'; // Equivalent to "primary" color in Vuetify
                case 'Secondary':
                return '#48a9a6a8'; // Equivalent to "secondary" color in Vuetify
                case 'Support':
                return '#666666a8'; // Equivalent to "default" color in Vuetify
                default:
                return ''; // Fallback
            }
        },
        getChipColor(type) {
            switch (type) {
                case 'Primary':
                return 'primary'; // Background color for PRIMARY chip
                case 'Secondary':
                return 'secondary'; // Background color for SECONDARY chip
                case 'Support':
                return 'default'; // Background color for SUPPORT chip
                default:
                return ''; // Fallback
            }
        },


        clearSliderIndex(setDataType)
        {
            if(setDataType === "warmup")
            {
                this.sliderWarmupSet = null;
                this.sliderWarmupSetDataType = null;
                this.sliderWarmupSetIsWeights = null;
            }
            else
            {
                this.sliderWorkSet = null;
                this.sliderWorkSetDataType = null;
                this.sliderWorkSetIsWeights = null;
            }
          
        },


        clearSliders()
        {
            this.sliderWarmupSet = null;
            this.sliderWarmupSetDataType = null;

            this.sliderWarmupSetIsWeights = null;

            this.sliderWorkSet = null;
            this.sliderWorkSetDataType = null;
            
            this.sliderWorkSetIsWeights = null;
        },


        setSlider(index, set, setDataType, isWeights) {

            if(this.sliderActiveExercise !== index)
            {
                this.clearSliders();
            }

            this.sliderActiveExercise = index;

            if(setDataType === "warmup")
            {
                this.sliderWarmupSet = set;
                this.sliderWarmupSetDataType = setDataType;
                this.sliderWarmupSetIsWeights = isWeights;
            }
            else
            {
                this.sliderWorkSet = set;
                this.sliderWorkSetDataType = setDataType;
                this.sliderWorkSetIsWeights = isWeights;
                
            }

        },



        loadWorkout() {
            const workoutsList = JSON.parse(localStorage.getItem('workoutsList')) || [];
            const workout = workoutsList.find(w => w.key === this.workoutKey);
            if (workout) {
                this.routineDescription = workout.routineDescription;
                this.dayOfWeek = workout.dayOfWeek;
                this.isFavorite = workout.isFavorite;
                this.exercises = workout.exercises;
                this.createdDate = workout.createdDate;
            }
        },

      goBackToWorkoutList() {
        this.$router.push({ path: '/', name: 'WorkoutList' });
      },
      saveRoutine() {
        const workout = {
            key: this.workoutKey,
            routineDescription: this.routineDescription,
            dayOfWeek: this.dayOfWeek,
            isFavorite: this.isFavorite,
            exercises: this.exercises,
            createdDate: this.createdDate
        };
        let workoutsList = JSON.parse(localStorage.getItem('workoutsList')) || [];
        const existingIndex = workoutsList.findIndex(w => w.key === this.workoutKey);
        if (existingIndex !== -1) {
            // Update existing workout
            workoutsList[existingIndex] = workout;
        } else {
            // Add new workout
            workoutsList.push(workout);
        }
        localStorage.setItem('workoutsList', JSON.stringify(workoutsList));
      },
      copyWorkout() {
        const newWorkout = {
            key: this.generateGUID(),
            routineDescription: this.routineDescription + ' [COPY]',
            dayOfWeek: this.dayOfWeek,
            isFavorite: this.isFavorite,
            exercises: JSON.parse(JSON.stringify(this.exercises)),
            createdDate: new Date().toISOString()
        };

        this.workoutKey = newWorkout.key;
        this.routineDescription = newWorkout.routineDescription;
        this.dayOfWeek = newWorkout.dayOfWeek;
        this.isFavorite = newWorkout.isFavorite;
        this.exercises = newWorkout.exercises;
        this.createdDate = newWorkout.createdDate;

        // Save copied workout
        this.saveRoutine();
      },
      deleteWorkout() {
        let workoutsList = JSON.parse(localStorage.getItem('workoutsList')) || [];
        workoutsList = workoutsList.filter(workout => workout.key !== this.workoutKey);
        localStorage.setItem('workoutsList', JSON.stringify(workoutsList));
        this.$router.push({ path: '/', name: 'WorkoutList' });
      },


        // Function to find an exercise by ID
        findExerciseById(id) {
            return this.exercises.find((exercise) => exercise.id === id);
        },

        // Function to delete an exercise by ID
        deleteExerciseById(id) {
            const index = this.exercises.findIndex((exercise) => exercise.id === id);
            if (index !== -1) {
            this.exercises.splice(index, 1);
            return true; // Return true if the exercise was found and deleted
            }
            return false; // Return false if the exercise was not found
        },

        // Function to retrieve an exercise by ID
        getExerciseById(id) {
            return this.findExerciseById(id);
        },

        // Add an exercise to the list
        addExercise(exercise) {
            this.exercises.push(exercise);
        },

        addNewExercise(type) {
            const exercise = {
            id: this.generateGUID(),
            type: type,
            name: '',
            weight: 0,
            warmupReps: 5,
            workingReps: 5,
            warmupSets: [],
            workSets: []
            };

            this.addExercise(exercise);
        },

        removeExercise(exercise) {
            this.deleteExerciseById(exercise.id);
        },


        
    // Function to move an exercise up or down in the list by ID, only if it has the same type
    moveExercise(item, direction) {

        const index = this.exercises.findIndex((exercise) => exercise.id === item.id);
        if (index === -1) {
            return false; // Return false if the exercise was not found
        }

        const exercise = this.exercises[index];
        const targetIndex = direction === 'up' ? index - 1 : index + 1;

        // Ensure the target index is within bounds and has the same type
        if (
            targetIndex >= 0 &&
            targetIndex < this.exercises.length &&
            this.exercises[targetIndex].type === exercise.type
        ) {
            // Swap the exercises
            [this.exercises[index], this.exercises[targetIndex]] = [
                this.exercises[targetIndex],
                this.exercises[index]
            ];
            return true; // Return true if the exercise was moved
        }

        return false; // Return false if the move was not possible
    },

    canMove(item, direction) {
        const index = this.exercises.findIndex((exercise) => exercise.id === item.id);
        if (index === -1) {
            return false; // Return false if the exercise was not found
        }

        if(direction ==='up')
        {
            return this.exercises.slice(0, index).some(e => e.type === item.type);
        }
        else
        {
            return this.exercises.slice(index + 1).some(e => e.type === item.type);
        }
    },


    // Function to update an exercise by passing in an updated exercise object
    updateExercise(updatedExercise) {
        const index = this.exercises.findIndex((exercise) => exercise.id === updatedExercise.id);
        if (index !== -1) {
            this.exercises[index] = updatedExercise;
            return true; // Return true if the exercise was found and updated
        }
        return false; // Return false if the exercise was not found
    },


      generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
      },

      


      addWarmupSet(exercise) {

        let warmupSet;
        if (exercise.warmupSets.length === 0) {
            warmupSet = { weight: Math.round(exercise.weight * 0.6), reps: exercise.warmupReps, complete: false };
        } else if (exercise.warmupSets.length === 1) {
            warmupSet = { weight: Math.round(exercise.weight * 0.7), reps: exercise.warmupReps, complete: false };
        } else if (exercise.warmupSets.length === 2) {
            warmupSet = { weight: Math.round(exercise.weight * 0.85), reps: exercise.warmupReps, complete: false };
        } else {
            warmupSet = { weight: Math.round(exercise.weight * 0.85), reps: exercise.warmupReps, complete: false };
        }

        exercise.warmupSets.push(warmupSet);

        this.updateExercise(exercise);
    },

    addWorkSet(exercise) {

        const workSet = {
          weight: exercise.weight,
          reps: exercise.workingReps,
          complete: false
        };
        exercise.workSets.push(workSet);

        this.updateExercise(exercise);
    },




      removeSet(exercise, setIndex, setType) {

        if(setType === "warmup")
        {
            exercise.warmupSets.splice(setIndex, 1);
        }
        else
        {
            exercise.workSets.splice(setIndex, 1);
        }

        this.updateExercise(exercise);

        this.clearSliderIndex(setType);
      },




       updateSetWeight(set , amount) {
            var updatedValue = Math.max(0, set.weight + amount);
            if (updatedValue >= 1 && updatedValue <= 500) {

                set.weight = updatedValue;
            }
        },

      updateSetReps(set , amount) {
            var updatedValue = Math.max(0, set.reps + amount);
            if (updatedValue >= 1 && updatedValue <= 99) {

                set.reps = updatedValue;
            }
        },

        updateExerciseWeight(exercise , amount) {
            var updatedValue = Math.max(0, exercise.weight + amount);
            if (updatedValue >= 1 && updatedValue <= 500) {

                exercise.weight = updatedValue;
            }
        },

      updateExerciseWorkingReps(exercise , amount) {
            var updatedValue = Math.max(0, exercise.workingReps + amount);
            if (updatedValue >= 1 && updatedValue <= 99) {

                exercise.workingReps = updatedValue;
            }
        },
        updateExerciseWarmupReps(exercise , amount) {
            var updatedValue = Math.max(0, exercise.warmupReps + amount);
            if (updatedValue >= 1 && updatedValue <= 99) {

                exercise.warmupReps = updatedValue;
            }
        },




      exportRoutine() {
        const data = {
          routineDescription: this.routineDescription,
          dayOfWeek: this.dayOfWeek,
          isFavorite: this.isFavorite,
          exercises: this.exercises
        };

        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "routine.json");
        document.body.appendChild(downloadAnchorNode);

        downloadAnchorNode.click();
        downloadAnchorNode.remove();

      },

      importRoutine(event) {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = JSON.parse(e.target.result);
            this.routineDescription = data.routineDescription || '';
            this.dayOfWeek = data.dayOfWeek || '';
            this.isFavorite = data.isFavorite || false;
            this.exercises = data.exercises || [];
          } catch (error) {
            alert('Invalid JSON file');
          }
        };
        reader.readAsText(file);
      }
    }
  };
  </script>
  




<style lang="scss" >
.v-container {
  padding: 20px;
}
.v-card {
  margin-bottom: 0px;
}
.v-btn {
  margin: 10px 0;
}
.v-delete-btn {
    padding: inherit;
}

.v-input__details {
    min-height: 0px;
    padding-top: 0px;
}

/* Override Vuetify's default .v-input__details styles */
.v-input__details {
  display: none !important;
}
.v-text-field .v-input__details 
{
    padding-inline: inherit !important;
}

.v-field__input
{
    font-size: large !important;
}
.no-bottom-padding {
    padding-bottom: 0px !important;
}
.no-top-padding {
    padding-top: 0px !important;
}
.no-left-padding {
    padding-left: 0px !important;
}
.no-right-padding {
    padding-right: 0px !important;
}
.v-row-margin-fix
{
    margin-left: 1px !important;
    margin-right: 10px !important;
}

.v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 5px) !important; 
    height: calc(var(--v-btn-height) + 5px) !important;
}

.v-field{
    --v-field-input-padding-bottom: var(--v-field-padding-bottom, 1px) !important;
}

.v-btn--size-default-smaller
{
    --v-btn-height: 25px !important;
    font-size: xx-small !important;
    margin-right: 25px !important;
    margin-left: -5px !important;
    background-color: rgb(255 224 224) !important
}

.v-btn-close-small
{
    --v-btn-height: 25px !important;
    font-size: x-small !important;
    background-color: rgb(253, 207, 207) !important
}
</style>
