import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Global CSS has to be imported
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';


  const vuetify = createVuetify({
    components,
    directives,
  });
  
  const app = createApp(App);
  
  app.use(router);
  app.use(vuetify);
  
  app.mount('#app');